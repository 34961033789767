import communityDetails from "../../Common/Models/CommunityDetails";
import FinalResponse from "../../Common/Models/FinalResponse";
import goldCard from '../../Common/Images/goldcard.png'
import greenCard from '../../Common/Images/greencard.png'
import isMobile from "is-mobile";

type ThanksProps = {
  status: 0 | 1 | 2 | 3,
  username: string,
  brandDetails: communityDetails | null,
  final: FinalResponse | null
}

const PlumThanks = (
  {
    status,
    username,
    brandDetails,
    final
  }: ThanksProps
) => {

  if(status === 0 || !brandDetails || !final) return null

  const toTemplate = (s: string) => {
    let influencer = username
    influencer = influencer.replace("@", "")
    return eval('`' + s + '`')
  }

  return(
    <div className={"ib-card-parent"}>
      <div className={"card ib-community-card switched"}>
        <div className={"card-content ib-card-content has-text-centered"}>
          <h4 className={"title thanksTitle is-size-3-tablet"}>
            You're almost at the party!
            <br />
            One last step...
          </h4>
          <div className={`has-text-centered-desktop ${isMobile()?"px-5":"px-6"}`}>
            <h5
              className={"nextStepsTitle has-text-centered title is-size-5-desktop"}
            >
              Mention @plumgoodness in your Instagram story
            </h5>
          </div>
          <div className={"content has-text-centered pt-5"}>
            <h4 className={"has-text-centered rewardsThanksTitle"}>
              and YOUR Gift 🎁 will be:
            </h4>
            <div
              className={"card thanksExclusiveCard"}
              hidden={!final.uniq}
            >
              <div className={"card-content"}>
                <div className={"columns is-mobile is-vcentered"}>
                  <div className={"column py-1 is-1-tablet is-2-mobile pr-0 pl-3"}>
                    <div
                      className={"imageCircle"}
                    >
                      <img
                        src={goldCard}
                        width={isMobile() ? 25 : 40}
                      />
                    </div>
                  </div>
                  <div className={"column is-3 py-1 px-1"}>
                    <h6 className={"exclusiveText"}>
                      exclusive
                    </h6>
                  </div>
                  <div className={"column is-4-tablet py-1 is-5-mobile px-1"}>
                    <button
                      className={"button px-2 rewardsThanksCardButton"}
                    >
                      {final.uniq}% coupon
                    </button>
                  </div>
                  <div className={`column is-3-tablet py-1 is-2-mobile ${isMobile()?"px-2 pr-3":"px-1"}`}>
                    <span className={"forYouText"}>
                      for you
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              hidden={!final.main}
            >
              <h5 className={"title turnUsernameText is-size-6 pt-5 px-3 mb-2"}>
                We turn your username "{username}" into a
              </h5>
              <div
                className={"card thanksExclusiveCard"}
              >
                <div className={"card-content"}>
                  <div className={"columns is-multiline is-centered is-mobile is-vcentered"}>
                    <div className={"column is-1-tablet py-1 is-2-mobile pr-0 pl-3"}>
                      <div
                        className={"imageCircle"}
                      >
                        <img
                          src={greenCard}
                          width={isMobile() ? 25 : 40}
                        />
                      </div>
                    </div>
                    <div className={"column py-1 px-1"}>
                      <button
                        className={`button ${isMobile()?"px-2":"px-3"} rewardsThanksCardButton`}
                      >
                        {final.main}% coupon above ₹999
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p
              hidden={status !== 2}
              className={"pt-4 pvtUsernameText is-size-6-mobile"}
            >
              PS: Please <b>make your Instagram account {username?.replace("@", "")} public</b> before posting so we can detect the mention and send the discount code.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlumThanks