import {Result} from "antd";

const NotFound = () => {

  return(
    <div className={"hero is-fullheight"}>
      <div className={"hero-body"}>
        <div className={"container has-text-centered"}>
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
          />
        </div>
      </div>
    </div>
  )
}

export default NotFound;