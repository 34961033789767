import communityDetails from "../../Common/Models/CommunityDetails";
import FinalResponse from "../../Common/Models/FinalResponse";
import goldCard from '../../Common/Images/goldcard.png'
import greenCard from '../../Common/Images/greencard.png'
import isMobile from "is-mobile";

type ThanksProps = {
  status: 0 | 1 | 2 | 3,
  username: string,
  brandDetails: communityDetails | null,
  final: FinalResponse | null
}

const Thanks = (
  {
    status,
    username,
    brandDetails,
    final
  }: ThanksProps
) => {

  if(status === 0 || !brandDetails || !final) return null

  const toTemplate = (s: string) => {
    let influencer = username
    influencer = influencer.replace("@", "")
    return eval('`' + s + '`')
  }

  return(
    <div className={"ib-card-parent"}>
      <div className={"card ib-community-card switched"}>
        <div className={"card-content ib-card-content has-text-centered"}>
          <h4 className={"title thanksTitle is-size-3-tablet"}>
            One last step to become our Ambassador ...
          </h4>
          <div className={`has-text-centered-desktop ${isMobile()?"px-5":"px-6"}`}>
            {/*<h4 className={"nextTitle title is-size-4-desktop"}>*/}
            {/*  Next Steps:*/}
            {/*</h4>*/}
            <h5
              className={"nextStepsTitle has-text-left title is-size-5-desktop"}
            >
              <ul>
                <li>
                  Mention @{brandDetails.brand_name} in your Instagram story
                </li>
                <li>
                  We'll verify within 2 hours and send the rewards👇
                </li>
              </ul>
            </h5>
          </div>
          <div className={"content has-text-centered pt-5"}>
            <h4 className={"rewardsThanksTitle"}>
              Rewards you’ll get:
            </h4>
            <div
              className={"card thanksExclusiveCard"}
              hidden={!final.uniq}
            >
              <div className={"card-content"}>
                <div className={"columns is-mobile is-vcentered"}>
                  <div className={"column py-1 is-1-tablet is-2-mobile pr-0 pl-3"}>
                    <div
                      className={"imageCircle"}
                    >
                      <img
                        src={goldCard}
                        width={isMobile() ? 25 : 40}
                      />
                    </div>
                  </div>
                  <div className={"column is-3 py-1 px-1"}>
                    <h6 className={"exclusiveText"}>
                      exclusive
                    </h6>
                  </div>
                  <div className={"column is-4-tablet py-1 is-5-mobile px-1"}>
                    <button
                      className={"button px-2 rewardsThanksCardButton"}
                    >
                      {final.uniq}% coupon
                    </button>
                  </div>
                  <div className={`column is-3-tablet py-1 is-2-mobile ${isMobile()?"px-2 pr-3":"px-1"}`}>
                    <span className={"forYouText"}>
                      for you
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              hidden={!final.main}
            >
              <h5 className={"title turnUsernameText is-size-6 pt-5 px-3 mb-2"}>
                We turn your username "{username}" into a
              </h5>
              <div
                className={"card thanksExclusiveCard"}
              >
                <div className={"card-content"}>
                  <div className={"columns is-multiline is-mobile is-vcentered"}>
                    <div className={"column is-1-tablet py-1 is-2-mobile pr-0 pl-3"}>
                      <div
                        className={"imageCircle"}
                      >
                        <img
                          src={greenCard}
                          width={isMobile() ? 25 : 40}
                        />
                      </div>
                    </div>
                    <div className={"column is-4-tablet py-1 is-5-mobile px-1"}>
                      <button
                        className={`button ${isMobile()?"px-2":"px-3"} rewardsThanksCardButton`}
                      >
                        {final.main}% coupon
                      </button>
                    </div>
                    <div className={`column py-1 ${isMobile()?"px-1":"px-0"}`}>
                    <span className={"forYouNFamText"}>
                      for you, friends and family
                    </span>
                    </div>
                    <div className={"column py-1 is-12"}>
                      <span className={"shareRewardText"}>
                        (psst, you can share this with your followers 😉)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p
              hidden={status !== 2}
              className={"pt-4 pvtUsernameText is-size-6-mobile"}
            >
              PS: Please <b>make your Instagram account {username?.replace("@", "")} public</b> before posting so we can detect the mention and send the discount code.
            </p>
          </div>
        </div>
      </div>
    </div>
  )

  // return(
  //   <div className={"ib-card-parent"}>
  //     <div className={"card ib-community-card switched"}>
  //       <div className={"card-content ib-card-content has-text-centered"}>
  //         <h4 className={"title is-size-3-tablet"}>
  //           {toTemplate(brandDetails.thanks_headline)}
  //         </h4>
  //         <div className="has-text-centered">
  //           <p className="join-head is-size-4-desktop pb-2">
  //             {toTemplate(brandDetails.thanks_box_head)}
  //           </p>
  //           <p className="ibBox dark has-text-left has-text-weight-bold">
  //             {toTemplate(brandDetails.thanks_box_content)}
  //           </p>
  //         </div>
  //         <div className={"content has-text-left pt-5"}>
  //           <p
  //             dangerouslySetInnerHTML={{__html: toTemplate(brandDetails.thanks_message)}}
  //           />
  //           <br />
  //           <p
  //             hidden={status !== 2}
  //           >
  //             PS: Please <b>make your Instagram account {username?.replace("@", "")} public</b> before posting so we can detect the mention and send the discount code.
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default Thanks