import {useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import LoadModal from "../../components/LoadModal";
import brandDetails from "../../Common/Models/brandDetails";
import GetCommunityForm from "../../Common/ApiCall/GetCommunityForm";
import {BadNotif} from "../../Common/Utils/SendNotification";
import SEO from "../../components/SEO";
import {ServerURL} from "../../Common/ApiCall/ApiConstants";
import isMobile from "is-mobile";
import {message, Spin} from "antd";
import ProductPage from "./ProductPage";
import StepComponent from "./StepComponent";
import CollectAddress from "./CollectAddress";
import darkPattern from './pattern1dark.png'

export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)

export type pageTypes = "details" | "product" | "address"

const EMAIL_IN_USERNAME_WARNING_MESSAGE = "Enter your Instagram Username not email.\nCopy and paste your instagram username or Instagram Profile URL."

export type AddressObj = {
    line_1: string,
    line_2: string,
    city: string,
    state: string,
    country: string,
    pin: string
}

export const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

const BrandPage = () => {
    const p = useParams()
    const [searchP] = useSearchParams()
    const [bName, setBname] = useState<string | null>(null)
    const [custView, setCustView] = useState(false)
    const [it, setIt] = useState(true)
    const [bDetails, setBDetals] = useState<null | brandDetails>(null)
    const [thanks, setThanks] = useState(false)
    const [redirectTime, setRedirecttime] = useState<number | null>(null)
    const [cinterval, setCinterval] = useState<any>(null)
    const [nxt, setNxt] = useState(false)
    const [finalPage, setFinalPage] = useState<{valid: boolean, private: boolean} | null>(null)
    const [curr, setCurr] = useState(0)
    const [currPage, setCurrPage] = useState<pageTypes>("details")
    const [totSteps, setTotSteps] = useState(3)
    const [load, setLoad] = useState(false)
    const [adr, setAdr] = useState<AddressObj>({
        line_1: "", line_2: "", city: "", country: "", pin: "", state: ""
    })
    const [val, setVal] = useState<{
        email: string,
        instagram: string,
        contact: string,
        address: string,
        product_id: number | null,
        name: string, youtube: string
    }>({
        email: "",
        instagram: "",
        contact: "",
        address: "",
        product_id: null,
        name: "",
        youtube: ""
    })
    const sullenBg = "https://i.ibb.co/cwpQnPq/sullen-bg.jpg"

    async function getbranddetails() {
        if(bName) {
            const res = await GetCommunityForm(bName)
            setIt(false)
            if(res.success) {
                let data = res.data
                if((data.show_address ? 1 : 0)
                  + ((data.is_giveaway_active && data.influencer_giveaway_products && data.influencer_giveaway_products.length > 0) ? 1 : 0)) {
                    data.eligible = false
                }
                if(p && p.brand && searchP.get("view")) {
                    data.eligible = true
                }
                setBDetals(data)
                //showFinalPage(false)
                const ps = data.influencer_giveaway_products
                setTotSteps( (data.show_address ? 1 : 0)
                  + ((data.is_giveaway_active && data.influencer_giveaway_products && data.influencer_giveaway_products.length > 0) ? 1 : 0))
                if(ps.length > 0) setVal({...val, product_id: ps[0].id})
            } else {
                BadNotif(res)
            }
        }
    }

    useEffect(() => {
        getbranddetails()
    }, [bName])

    useEffect(() => {
        if(p && p.brand) setBname(p.brand)
        const v = searchP.get("view")
        if(v) {
            setCustView(v === "customer")
            //window.location.search = ""
        }
    }, [])

    useEffect(() => {
        //console.log(currPage, val, curr, totSteps)
    }, [currPage , curr, totSteps, val])

    const loading = () => setLoad(true)

    const showFinalPage = (is_private: boolean) => {
        setFinalPage({valid: true, private: is_private})
    }

    useEffect( () =>{
        if(finalPage?.private) {
            const a = document.getElementsByClassName("head-box-content-pvt")[0];
            a.innerHTML = `PS: Please <b>make your Instagram account @${val.instagram.replace("@", "")} public</b> before posting so we can detect the mention and send the discount code.`
        }
    }, [finalPage])

    const loadingEnd = () => setLoad(false)

    function getSubmitButtonDetails() {
        //console.log(`Page: ${currPage}, Tot: ${totSteps}`)
        if(custView) return "submit"
        switch (currPage) {
            case "details": return totSteps===0 ? "submit" : "next";
            case "address": return "submit";
            case "product": return totSteps === 1 ? "submit" : "next";
            default: return "submit"
        }
    }

    function nextPage(e: any) {
        if(!validateEmail(val.email)) {
            message.warn("Enter Valid Email to Continue")
            return
        }
        switch (currPage) {
            case "details": submitUserDetails(e);break;
            case "product": nextProdPage(e);break;
            case "address": submit(e);break;
        }
    }

    function changePage(a: pageTypes) {setCurrPage(a)}

    function nextProdPage(e: any) {
        if(totSteps===2) {
            setCurrPage('address')
            setCurr(curr+1)
        } else {
            submit(e)
        }
    }

    const submitted = (thanks = true) => {
        setThanks(thanks)
    }

    function submit(e: any) {
        e.preventDefault()
        if(!val.name) {message.warn( "Enter Name to Continue");return;}
        if(!val.email) {message.warn( "Enter Email to Continue");return;}
        if(!val.instagram) {message.warn( "Enter Instagram Username to Continue");return;}
        const username = val.instagram.trim()
        if(username.indexOf(" ") >= 0) {
            console.warn("Username contains spaces", username);
            message.warn("Instagram Username doesn't contains spaces.\nCopy and paste your instagram username or Instagram Profile URL.");
            return;
        }
        if(username.indexOf("@gmail.com") >= 0) {
            console.warn("Username contains email", username);
            message.warn(EMAIL_IN_USERNAME_WARNING_MESSAGE);
            return;
        }
/*        if(bDetails?.is_giveaway_active && bDetails?.show_address && !val.address) {
            console.warn("Address Not Found");
            message.warn("Enter Address to Continue");
            return;
        }*/
        let a = null
        if(bDetails?.show_address && !custView ) {
            if(!(adr.line_1 && adr.line_2 && adr.city && adr.state && adr.country && adr.pin)) {
                console.warn("Address Not Found")
                message.warn("Enter Address to Continue")
                return;
            } else {
                a = [adr.line_1, adr.line_2, adr.city, adr.state, adr.country, adr.pin].join(", ")
            }
        }
        const data = {
            username: username,
            hook_id: bDetails?.id,
            email: val.email,
            contact: val.contact,
            address: a,
            product_id: val.product_id,
            name: val.name,
            description: val.youtube,
            mobile: isMobile() ? "yes" : "no"
        }
        if(bDetails?.eligible) {
            loading()
            fetch(ServerURL("/influencer/eligible/submit"), {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
              .then(r => r.json())
              .then(r => {
                  loadingEnd()
                  if(r.success) {
                      if(r.data.valid) showFinalPage( r.data.private)
                      submitted()
                  } else throw r.errors[0]
              })
              .catch(e => {
                  loadingEnd()
                  BadNotif(null, e.message)
              })
        } else {
            fetch(ServerURL("/influencer/submit"), {
                method: "POST",
                body: JSON.stringify({
                    email: val.email,
                    username: username,
                    contact: val.contact,
                    hook_id: bDetails?.id,
                    address: a,
                    product_id: val.product_id,
                    name: val.name,
                    description: val.youtube,
                    mobile: isMobile() ? "yes" : "no"
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            setThanks(true)
            if(bDetails?.redirect_url && bDetails?.redirect_time_seconds) {
                setRedirecttime(bDetails.redirect_time_seconds)
                counterInterval(bDetails.redirect_time_seconds)
            }
            submitted()
        }
    }

    function submitUserDetails(e: any) {
        e.preventDefault()
        if(!val.email) {BadNotif(null, "Enter Email to Continue");return;}
        if(!val.instagram) {BadNotif(null, "Enter Instagram Username to Continue");return;}
        const username = val.instagram.trim()
        if(username.indexOf(" ") >= 0) {
            console.warn("Username contains spaces", username);
            message.warn("Instagram Username doesn't contains spaces.\nCopy and paste your instagram username or Instagram Profile URL.");
            return;
        }
        if(username.indexOf("@gmail.com") >= 0) {
            console.warn("Username contains email", username);
            message.warn("Enter your Instagram Username not email.\nCopy and paste your instagram username or Instagram Profile URL.");
            return;
        }
        if(bDetails?.is_giveaway_active && bDetails.influencer_giveaway_products && bDetails.influencer_giveaway_products.length > 0 && !custView) {
            setCurr(curr + 1)
            setCurrPage('product')
            //setNxt(true)
        } else {
            if(bDetails?.show_address && !custView) {
                setCurr(curr+1)
                setCurrPage('address')
                //setNxt(true)
            } else {
                submit(e)
            }
        }
    }

    //@ts-ignore
    const toTemplate = (s: string) => {
        let influencer = val.instagram
        influencer = influencer.replace("@", "")
        return eval('`' + s + '`')
    }

    useEffect(() => {
        if(cinterval && (redirectTime !== null) && redirectTime <= 0) {
            setRedirecttime(0)
            clearInterval(cinterval)
            if(bDetails?.redirect_url) window.open(bDetails.redirect_url, "_self")
        }
    }, [redirectTime])

    const counterInterval = (time: number) => {
        const interval = setInterval(() => {
            setRedirecttime(a => Math.round(((a || time) - 1)*100)/100)
        }, 1000)
        setCinterval(interval)
        return () => {clearInterval(interval)}
    }

    let siderImageStyle = {}
    siderImageStyle = bDetails?.banner ? {
        backgroundImage: `url(${bDetails.banner})`,
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    } : {}
    if((bDetails?.modal_img && finalPage)) {
        siderImageStyle = {
            backgroundRepeat: "no-repeat",
            minHeight: "100vh",
            backgroundPosition: 'center',
            backgroundImage: `url(${darkPattern})`
        }
    }
    let siderImageStyleChild = (bDetails?.modal_img && finalPage) ? {
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        backgroundPosition: 'center',
        backgroundImage: `url(${bDetails.modal_img})`,
        zIndex: 2
    } : {}

    if(bDetails) {
        return (
            <div
              className={"columns is-gapless is-align-items-flex-start"}
              style={{
                  minHeight: "100vh",
                  flexDirection: "row-reverse"
              }}
            >
                <SEO
                  title={capitalize(bDetails.brand_name)}
                  img={bDetails.brand_logo}
                />
                <div
                    className={`column col-card ct1 ${currPage!=='details' ? "is-8 is-9-desktop is-10-tablet" : "is-7 is-8-desktop is-9-tablet"} has-background-white is-flex is-align-items-center`}
                    style={{
                        boxShadow: "0px 12px 10px rgba(76, 73, 129, 0.14)",
                        borderRadius: "14px"
                    }}
                >
                    <div
                      className={"section ib-sec px-1 pt-3 pb-2"}
                      style={{
                          width: "100%"
                      }}
                    >
                        <div
                          className={"ib-widget-main"}
                          style={{
                              //@ts-ignore
                              textAlign: "-webkit-center"
                          }}
                        >
                            <div className={"ib-widget-container-main"}>
                                <div
                                  className={"has-text-left has-text-centered-mobile"}
                                >
                                    {bDetails.brand_logo && !finalPage && (
                                      <img
                                        src={bDetails.brand_logo}
                                        alt={bDetails.brand_name}
                                        title={bDetails.brand_name}
                                        className={"py-3 is-hidden-desktop"}
                                        width={isMobile() ? 200 : 300}
                                        style={bDetails.id===24 ? {
                                            maxHeight: 150,
                                            width: "auto"
                                        } : {}}
                                      />
                                    )}
                                    {finalPage && (
                                      <div className={"final_page_widget"}>
                                          <div className={"modal_heading pt-4"}>
                                              {toTemplate(bDetails.modal_heading || "")}
                                          </div>
                                          <br/>
                                          <div className="has-text-centered">
                                              <p className="join-head pb-2">
                                                  {toTemplate(bDetails.modal_box_head || "")}
                                              </p>
                                              <p className="ibBox join-head-content dark has-text-left has-text-weight-bold">
                                                  {toTemplate(bDetails.modal_box_content || "")}
                                              </p>
                                          </div>
                                          <br/>
                                          <div className="ibBox has-text-left flexd main_content_ib">
                                              <p
                                                className="head-box-content"
                                                dangerouslySetInnerHTML={{__html: toTemplate(bDetails.modal_content)}}
                                              >
{/*                                                  <i
                                                    className="fa fa-info-circle rt-gp"
                                                    aria-hidden="true"
                                                  />
                                                  As soon as you do it, we'll send you an <b>exclusive
                                                  coupon for 20% off</b> sitewide for your next purchase!
                                                  <br/><br/>
                                                  <b>Additional Bonus:</b> Your IG username “{val.instagram.replace("@", "")}” will be made into a&nbsp;
                                                  <b>15% discount code</b> on our website for your friends & family!
                                                  <br /><br />
                                                  <i
                                                    className="fa fa-x fa-envelope"
                                                    aria-hidden="true"
                                                  />
                                                  We’ve also sent you a mail with all the info.
                                                  <br/><br/>
                                                  <span id="ibInfluencerPrivate" hidden={!finalPage.private}>
                                                      PS: Please <b className="is-bold">make your Instagram account @{val.instagram.replace("@", "")} public</b> before posting so we can detect the mention and send the discount code.
                                                  </span>*/}
                                              </p>
                                              <p
                                                className={"head-box-content-pvt"}
                                              />
                                          </div>
                                      </div>
                                    )}
                                    {thanks ? (
                                      <div className={"head-block"}>
                                          {
                                              !finalPage?.valid && (
                                              <h1 className={"ib-form-head is-size-2-desktop mt-5"}>
                                                  {bDetails.thank_you_text || "Thank You! We will reach out to you soon."}
                                              </h1>
                                            )
                                          }
                                          {redirectTime !== null && (
                                            <h2 className={"ib-form-subhead is-size-5-desktop is-size-6-mobile"}>
                                                {`Redirecting in ${redirectTime} seconds`}
                                            </h2>
                                          )}
                                      </div>
                                    ) : (
                                      <div className={"head-block"}>
                                          <h1
                                            className={"ib-form-head is-size-2-widescreen is-size-3-tablet is-size-4-mobile"}
                                            hidden={currPage !== 'details'}
                                          >
                                              {bDetails.heading}
                                          </h1>
                                          <h2
                                            className={"ib-form-subhead is-size-5-widescreen is-size-6-mobile"}
                                            hidden={currPage !== 'details'}
                                          >
                                              {bDetails.sub_heading}
                                          </h2>
                                      </div>
                                    )}
                                </div>
                                {
                                    !thanks && (
                                    <StepComponent
                                      currPage={currPage}
                                      address={bDetails.show_address}
                                      setCurr={(page: pageTypes) => changePage(page)}
                                      product={bDetails.is_giveaway_active && bDetails.influencer_giveaway_products && bDetails.influencer_giveaway_products.length > 0}
                                    />
                                  )
                                }
                                <form
                                  className={`ib-main-form ${nxt ? "mt-2":"mt-5"} has-text-centered`}
/*                                  onSubmit={e => bDetails.is_giveaway_active ? submitUserDetails(e) : submit(e)}*/
                                  hidden={thanks}
                                >
                                    {currPage==='details' && (
                                      <>
                                          <div
                                            className={"ib-form-email-comp"}
                                          >
                                              <i
                                                className="fa fa-ig-ib fa-2x fa-user"
                                                aria-hidden="true"
                                              />
                                              <input
                                                name={"name"}
                                                type={"text"}
                                                className={"ib-input ib-instagram-email-input"}
                                                placeholder={"Name"}
                                                onChange={e => setVal({...val, name: e.target.value})}
                                                value={val.name}
                                                required
                                              />
                                          </div>
{/*                                          <br className={"is-hidden-mobile"} />*/}
                                      </>
                                    )}
                                    {currPage==='details' && (
                                      <div
                                        className={"ib-form-email-comp"}
                                      >
                                          <i
                                            className="fa fa-ig-ib fa-2x fa-envelope"
                                            aria-hidden="true"
                                          />
                                          <input
                                            name={"email"}
                                            type={"email"}
                                            className={"ib-input ib-instagram-email-input"}
                                            placeholder={bDetails.email_ph || "Enter Email Here"}
                                            onChange={e => setVal({...val, email: e.target.value})}
                                            value={val.email}
                                            required
                                          />
                                      </div>
                                    )}
{/*                                    <br className={"is-hidden-mobile"} />*/}
                                    {bDetails.show_contact && currPage==='details' && (
                                      <>
                                          <div
                                            className={"ib-form-email-comp"}
                                          >
                                              <i
                                                className="fa fa-ig-ib fa-2x fa-phone"
                                                aria-hidden="true"
                                              />
                                              <input
                                                name={"contact"}
                                                type={"text"}
                                                className={"ib-input ib-instagram-email-input"}
                                                placeholder={bDetails.contact_ph || "Your Phone Number"}
                                                onChange={e => setVal({...val, contact: e.target.value})}
                                                value={val.contact}
                                                required
                                              />
                                          </div>
{/*                                          <br className={"is-hidden-mobile"} />*/}
                                      </>
                                    )}
                                    {currPage==='details' && (
                                      <>
                                          <div
                                            className={"ib-form-instagram-input-comp"}
                                          >
                                              <i
                                                className="fa fa-ig-ib fa-2x fa-instagram"
                                                aria-hidden="true"
                                              />
                                              <input
                                                type="text"
                                                id="ib-instagram-username-input"
                                                className="ib-input ib-instagram-username-input"
                                                placeholder={bDetails.ig_ph || "Your Instagram Username"}
                                                onChange={e => setVal({...val, instagram: e.target.value})}
                                                value={val.instagram}
                                                name="instagram_username"
                                                required
                                              />
                                          </div>
{/*                                          <br className={"is-hidden-mobile"} />*/}
                                      </>
                                    )}
                                    {currPage==='details' && bDetails.show_youtube && (
                                      <div
                                        className={"ib-form-input-comp"}
                                      >
                                          <i
                                            className="fa fa-ig-ib fa-2x fa-youtube-play"
                                            aria-hidden="true"
                                          />
                                          <input
                                            type="text"
                                            id="ib-instagram-youtube-input"
                                            className="ib-input ib-instagram-email-input"
                                            placeholder={"Youtube (Optional)"}
                                            onChange={e => setVal({...val, youtube: e.target.value})}
                                             value={val.youtube}
                                            name="youtube"
                                          />
                                      </div>
                                    )}
                                    {currPage==='address' && bDetails.show_address && (
                                      <CollectAddress
                                        address={adr}
                                        setAddress={(ad: AddressObj) => setAdr(ad)}
                                      />
                                    )}
                                    {currPage==='product' && bDetails.is_giveaway_active && bDetails.influencer_giveaway_products && bDetails.influencer_giveaway_products.length > 0 && (
                                      <ProductPage
                                        products={bDetails.influencer_giveaway_products}
                                        setProduct={(a: number) => setVal({...val, product_id: a})}
                                        productId={val.product_id}
                                        brand={bDetails}
                                      />
                                    )}
                                    <div className={"has-text-centered"}>
                                        <button
                                          className="ib-form-submit-button mt-4 mb-2 is-large is-uppercase px-6 is-size-4-desktop"
                                          onClick={e => nextPage(e)}
                                          disabled={load}
                                        >
                                            {load ? <>Please Wait... <Spin /></> : getSubmitButtonDetails()}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                  className={"column"}
                  style={siderImageStyle}
                >
                    <div
                      className={"section"}
                      style={siderImageStyleChild}
                    >
                        <div className={"container"}>
                            <div className={"content has-text-right"}>
                                {bDetails.brand_logo && (
                                  <img
                                    src={bDetails.brand_logo}
                                    alt={bDetails.brand_name}
                                    title={bDetails.brand_name}
                                    hidden={bDetails.id === 24}
                                    width={isMobile() ? 200 : 300}
                                    className={"py-3 is-hidden-touch"}
                                  />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"hero is-fullheight"}>
            <LoadModal load={it} />
            <div className={"hero-body"}>
                <div className={"container has-text-centered"}>
                    <div className={"section"}>
                        <h1 className={"heading"}>
                            <Spin />
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrandPage