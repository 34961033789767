import {Helmet} from 'react-helmet'

type SEOProps = {
  title: string,
  img: string | null
}

const SEO = (
  {
    title,
    img
  }: SEOProps
) => {

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={"Let us know your details to join our Influencer Program"} />
        <meta property="og:site_name" content={title} />
        {img && <meta property="og:image" content={img} />}
      </Helmet>
    </>
  )
}

export default SEO