import {useEffect, useState} from "react";
import GetCommunityForm from "../../Common/ApiCall/GetCommunityForm";
import {BadNotif} from "../../Common/Utils/SendNotification";
import {useParams, useSearchParams} from "react-router-dom";
import something from '../../Common/Images/something.png'
import {Button, Form, Input, message, Skeleton, Spin} from "antd";
import CommunityFormSubmit from "../../Common/ApiCall/CommunityFormSubmit";
import {capitalize, validateEmail} from "../BrandPage/BrandPage";
import isMobile from "is-mobile";
import SEO from "../../components/SEO";
import Thanks from "./Thanks";
import CommunityDetails from "../../Common/Models/CommunityDetails";
import Loader from "../../components/Loader";
import FAQs from "./FAQs";
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import {getPageVisits, visitPage} from "./TheCook";
import {SyncLoader} from "react-spinners";
import FinalResponse from "../../Common/Models/FinalResponse";
import bdayBanner from '../../Common/Images/PlumBirthdayDesktopBanner'
import plumBirthdayDesktopBanner from "../../Common/Images/PlumBirthdayDesktopBanner";
import plumBirthdayMobilebanner from "../../Common/Images/PlumBirthdayMobilebanner";
import PlumThanks from "./PlumThanks";
import p1 from '../../Common/Images/p1.png'
import p2 from '../../Common/Images/p2.png'
import p3 from '../../Common/Images/p3.png'
import p4 from '../../Common/Images/p4.png'

/*
0 - Form
1 - Submit (Not Eligible) [Thanks Page]
2 - Submit (Eligible) - Private
3 - Submit (Eligible) - Public
 */

const DEFAULT_CC = "IN"

const plumItems = [
  {
    title: "Fill IG username and email in the form",
    icon: p1
  },
  {
    title: "Tag @plumgoodness in your IG Story",
    icon: p2
  },
  {
    title: "Your IG should be Public (NOT Private)",
    icon: p4
  },
  {
    title: `We'll turn “your IG username” into a 10% coupon for you & your friends`,
    icon: p3
  },
]

const BrandApplication = () => {
  const [bName, setBname] = useState<string | number | null>(null)
  const [it, setIt] = useState(true)
  const [load, setLoad] = useState(false)
  const p = useParams()
  const [iForm] = Form.useForm()
  const [done, setDone] = useState<0 | 1 | 2 | 3>(0)
  const [searchP] = useSearchParams()
  const [brandDetails, setBrandDetails] = useState<CommunityDetails | null>(null)
  const [contactDetails, setContactDetails] = useState<any>({
    short: DEFAULT_CC,
    phone: new URLSearchParams(window.location.search).get("contact") || ""
  })
  const [finalResponse, setFinalResponse] = useState<FinalResponse | null>(null)

  const HERO_BODY_CLASSNAME = `hero-body ${document.referrer.includes("earthrhythm")&&isMobile() ?"mt-6":""}`

  useEffect(() => {
    getBrandDetails()
    seedInputsFromSearch()
    // setDone(2)
    // setFinalResponse({
    //   valid: true,
    //   private: true,
    //   uniq: null,
    //   main: 10
    // })
    // iForm.setFieldsValue({
    //   "ib-community-instagram": "amank123"
    // })
  }, [bName])

  const seedInputsFromSearch = () => {
    if(searchP) {
      let email = searchP.get("email")
      let name = searchP.get("name")
      let username = searchP.get("username")
      let contact = searchP.get("contact")
      if(email) {
        iForm.setFieldsValue({
          'ib-community-email': email
        })
      }
      if(username) {
        iForm.setFieldsValue({
          'ib-community-instagram': username
        })
      }
      if(name) {
        iForm.setFieldsValue({
          'ib-community-name': name
        })
      }
      if(contact) {
        if(contact.length < 11) {
          contact = DEFAULT_CC + contact
        }
        iForm.setFieldsValue({
          'ib-community-contact': contact
        })
      }
    }
  }

  useEffect(() => {
    // iForm.setFieldsValue({
    //   "ib-community-instagram": "aman"
    // })
    // setDone(3)
    // setLoad(true)
    // setTimeout(() => {
    //   setLoad(true)
    // }, 1000)
    // setTimeout(() => {
    //   iForm.setFieldsValue({
    //     "ib-community-instagram": "aman"
    //   })
    //   setLoad(false)
    //   setDone(2)
    // }, 2000)
  }, [])

  async function getBrandDetails() {
    if(bName) {
      let f = getPageVisits() ? "no" : "yes"
      let m = isMobile() ? "yes" : "no"
      visitPage()
      const res = await GetCommunityForm(bName, f, m)
      setIt(false)
      if(res.success) {
        let data = res.data
        setBrandDetails(data)
      } else {
        BadNotif(res)
      }
    }
  }

  function rgbToHsl(color: string) {
    if(!color) return;
    color = color.substring(1);
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);
    console.log(r, g, b)
    return 0.2126*r + 0.7152*g + 0.0722*b
  }

  async function formSubmit() {
    let a = iForm.getFieldsValue()
    console.log(a)
    if(!a["ib-community-instagram"]) {
      message.warn("Enter Instagram Username to Continue.");return;
    }
    let e = true
    let b = {
      email: a["ib-community-email"],
      page_id: bName,
      username: a["ib-community-instagram"],
      eligible: e ? "yes" : "no",
      mobile: isMobile() ? "yes" : "no",
      contact: a["ib-community-contact"]
    }
    if(brandDetails?.both_required) {
      if(!b.contact || b.contact.length < 7) {
        message.warn("Invalid Phone.");return;
      }
      if(!validateEmail(b.email)) {
        message.warn("Invalid Email.");return;
      }
    } else {
      if(brandDetails?.contact_required) {
        if(!b.contact || b.contact.length < 7) {
          message.warn("Invalid Phone.");return;
        }
      } else {
        if(!validateEmail(b.email)) {
          message.warn("Invalid Email.");return;
        }
      }
    }
    setLoad(true)
    const res = await CommunityFormSubmit(b)
    setLoad(false)
    if(res.success) {
      let d = res.data;
      if(e && d.valid) {
        setDone(d.private ? 2 : 3)
      } else {
        setDone(1)
      }
      setFinalResponse(d)
    } else {
      BadNotif(res)
    }
  }

  useEffect(() => {
    if(p && p.brand) setBname(p.brand)
  }, [])

  if(it || !brandDetails) {
    return (
      <section className="hero is-fullheight theme-bg">
        <div className={`${HERO_BODY_CLASSNAME} has-text-centered`}>
          <div className="container">
            <Skeleton active paragraph={{rows: 5}} />
          </div>
        </div>
      </section>
    )
  }

  function hexfix(str: string) {
    str = str.substring(1)
    if (str.length === 3) {
      str = str.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
    return str
  }

  const getTextColor = (color: string) => {
    color = hexfix(color)
    let c = "#fff"
    //if(l && l < 20) c = "#fff"
    return c
  }

  if(load) {
    return(
      <div className={"hero theme-bg"}>
        <SEO
          title={capitalize(brandDetails?.brand_name + " | Influencerbit Community")}
          img={brandDetails.brand_logo ?? ""}
        />
        <div className={HERO_BODY_CLASSNAME}>
          <div className={"container"}>
            <div className={"columns is-mobile is-multiline is-vcentered"}>
              <div className={"column is-6-tablet is-12-mobile"}>
                <div className={"ib-card-parent"}>
                  <div
                    className={"card ib-community-card"}
                    style={{
                      minHeight: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      className={"card-content ib-card-content has-text-centered"}
                      style={{
                        backgroundColor: brandDetails.bg_color,
                      }}
                    >
                      <SyncLoader
                        margin={7}
                        color={"rgb(24, 144, 255)"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={"column is-6-mobile-only has-text-centered"}>
                <img
                  src={something}
                  style={{maxWidth: "80%"}}
                  className={"mb-3 is-pulled-left"}
                />
                <img
                  className={"ib-community-image"}
                  src={brandDetails.images.length>0 ? brandDetails.images[0] : "https://uploads.dovetale.net/processed-landing-page-images/ac8da5449ec2d7541d3925da3054749d.png"}
                />
              </div>
              <div className={"column is-6-mobile-only has-text-centered"}>
                <img
                  src={brandDetails.images.length>1 ? brandDetails.images[1] : "https://uploads.dovetale.net/processed-landing-page-images/ac8da5449ec2d7541d3925da3054749d.png"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if(brandDetails.id === 84) {
    return(
      <div
        className={"hero"}
        style={{
          background: window.innerWidth<768 ? `#EEE4F8 url(${plumBirthdayMobilebanner})` : `#EEE4F8 url(${plumBirthdayDesktopBanner})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <SEO
          title={capitalize(brandDetails?.brand_name + "")}
          img={brandDetails.brand_logo ?? ""}
        />
        <div
          className={HERO_BODY_CLASSNAME}
          id={"hbd-plum"}
        >
          <div className={"container"}>
            <div className={"columns is-mobile is-multiline is-vcentered"}>
              <div className={"column is-12"}>
                <PlumThanks
                  status={done}
                  final={finalResponse}
                  brandDetails={brandDetails}
                  username={iForm.getFieldValue("ib-community-instagram") || ""}
                />
                <div className={"ib-card-parent"}>
                  <div
                    className={"card ib-community-card"}
                    hidden={done !== 0}
                  >
                    <div
                      className={"card-content ib-card-content has-text-centered pt-2 pb-0"}
                      style={{
                        backgroundColor: brandDetails.bg_color,
                        borderRadius: "13px"
                      }}
                    >
                      <div className={"columns is-multiline is-vcentered"}>
                        <div className={"column is-6-mobile-only has-text-centered"}>
                          <h4 className={"title is-size-2 is-size-5-mobile px-5"}>
                            {brandDetails.brand_title}
                          </h4>
                          <div
                            className={"content is-size-6-tablet px-5"}
                            style={window.innerWidth < 768 ? {
                              fontSize: "14px"
                            } : {}}
                          >
                            Plumster, wish us Happy Birthday on your Instagram Story, tag @plumgoodness
                            <span className={"has-text-weight-bold"}>
                    , and we'll make your IG Username into a discount code!!
                  </span>
                          </div>
                        </div>
                        <div className={"column is-half-tablet"}>
                          <div className={"content is-size-6-mobile py-2"}>
                            <Form
                              className={"has-text-left"}
                              form={iForm}
                              onFinish={() => formSubmit()}
                            >
                              <Form.Item
                                noStyle
                                hidden={!(brandDetails.both_required || !brandDetails.contact_required)}
                              >
                                <Form.Item
                                  name={"ib-community-email"}
                                  rules={[{
                                    required: true, type: 'email'
                                  }]}
                                  noStyle
                                >
                                </Form.Item>
                                <span
                                  className={"ib-form-label"}
                                >
                            Your Email
                          </span>
                                <br />
                                <input
                                  name={"email"}
                                  type={"email"}
                                  className={"ib-input-new ib-form-email-new-box"}
                                  placeholder={"Eg: name@gmail.com"}
                                  defaultValue={iForm.getFieldValue("ib-community-email")}
                                  onChange={e => iForm.setFieldsValue({"ib-community-email": e.target.value})}
                                  required
                                />
                              </Form.Item>
                              <Form.Item
                                noStyle
                                hidden={!(brandDetails.both_required || brandDetails.contact_required)}
                                shouldUpdate
                              >
                                {
                                  () => {
                                    return(
                                      <>
                                        <Form.Item
                                          name={"ib-community-contact"}
                                          rules={[{
                                            required: true
                                          }]}
                                          noStyle
                                        >
                                        </Form.Item>
                                        <label className={"ib-form-label"}>
                                          Your Phone Here
                                        </label>
                                        <div
                                          //className={"ib-form-email-comp"}
                                          className={"mb-2"}
                                        >
                                          <i
                                            className="fa fa-ig-ib fa-2x fa-phone"
                                            aria-hidden="true"
                                            hidden
                                          />
                                          <ConfigProvider locale={en}>
                                            <CountryPhoneInput
                                              placeholder={"Your Phone Here"}
                                              className={"ib-input-new"}
                                              value={contactDetails}
                                              name={"phone"}
                                              //className={"ib-input ib-instagram-email-input"}
                                              onChange={e => {
                                                setContactDetails(e)
                                                iForm.setFieldsValue({"ib-community-contact": (e?.code || "") + (e?.phone || "")})
                                              }}
                                              required
                                            />
                                          </ConfigProvider>
                                          <input
                                            name={"phone"}
                                            hidden
                                            type={"text"}
                                            className={"ib-input-new ib-form-contact-new-box"}
                                            placeholder={"Eg. 9896XXXXXX"}
                                            value={iForm.getFieldValue("ib-community-contact") || "123"}
                                            onChange={e => iForm.setFieldsValue({"ib-community-contact": e.target.value})}
                                          />
                                        </div>
                                      </>
                                    )
                                  }
                                }
                              </Form.Item>
                              <Form.Item
                                noStyle
                              >
                                <label className={"ib-form-label"}>
                                  Your Instagram Username
                                </label>
                                <Form.Item
                                  name={"ib-community-instagram"}
                                  noStyle
                                >
                                </Form.Item>
                                <input
                                  type="text"
                                  id="in-input-new ib-instagram-username-input"
                                  className="ib-input-new ib-form-username-new-box"
                                  placeholder={"Eg: @your_instagram_username"}
                                  onChange={e => iForm.setFieldsValue({"ib-community-instagram": e.target.value})}
                                  defaultValue={iForm.getFieldValue("ib-community-instagram")}
                                  required
                                />
                              </Form.Item>
                              <Form.Item
                                className={`has-text-centered mb-0`}
                                shouldUpdate
                              >
                                {() => {
                                  return(
                                    <button
                                      style={{
                                        backgroundColor: "#5E248C",
                                        color: getTextColor(brandDetails.button_color)
                                      }}
                                      disabled={load}
                                      className={`button is-dark ${isMobile()?"is-normal my-2":"is-medium my-3"} is-uppercase px-6 has-text-weight-bold`}
                                      onClick={() => formSubmit()}
                                    >
                                      {brandDetails.button_text}
                                    </button>
                                  )
                                }}
                              </Form.Item>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={"card"}>
              <div className={"card-content"}>
                <div className={"columns"}>
                  {plumItems.map((a, index) => (
                    <div
                      className={"column is-3-tablet is-centered has-text-centered-tablet "}
                      key={a.title}
                    >
                      <div className={"columns is-mobile is-vcentered is-multiline is-centered"}>
                        <div className={"column pr-1 is-3-mobile is-10-tablet"}>
                          <img
                            className={"is-display-block"}
                            src={a.icon}
                            alt={a.title}
                            width={window.innerWidth < 768 ? 50 : 150}
                            title={a.title}
                          />
                        </div>
                        <div className={"column pl-1 is-9-mobile is-10-tablet"}>
                          <div
                            className={"has-text-weight-bold"}
                            style={{
                              color: "#5E248C"
                            }}
                          >
                            {a.title}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {
            brandDetails.faqs.show && (
              <FAQs
                campaign_id={brandDetails.id}
                faqItems={brandDetails.faqs.items}
              />
            )
          }
        </div>
      </div>
    )
  }

  return(
    <div className={"hero theme-bg"}>
      <SEO
        title={capitalize(brandDetails?.brand_name + " | Influencerbit Community")}
        img={brandDetails.brand_logo ?? ""}
      />
      <div className={HERO_BODY_CLASSNAME}>
        <div className={"container"}>
          <div className={"columns is-mobile is-multiline is-vcentered"}>
            <div className={"column is-6-tablet is-12-mobile"}>
              <Thanks
                status={done}
                final={finalResponse}
                brandDetails={brandDetails}
                username={iForm.getFieldValue("ib-community-instagram") || ""}
              />
              <div className={"ib-card-parent"}>
                <div
                  className={"card ib-community-card"}
                  hidden={done !== 0}
                >
                  <div
                    className={"card-content ib-card-content has-text-centered pt-6 pb-0"}
                    style={{
                      backgroundColor: brandDetails.bg_color,
                      borderRadius: "30px"
                    }}
                  >
                    <h4 className={"title is-size-3 is-size-4-mobile"}>
                      {brandDetails.brand_title}
                    </h4>
                    <div className={"content is-size-6-mobile"}>
                      {brandDetails.brand_description}
                      <br /><br />
                      <Form
                        className={"has-text-left"}
                        form={iForm}
                        onFinish={() => formSubmit()}
                      >
                        <Form.Item
                          noStyle
                          hidden={!(brandDetails.both_required || !brandDetails.contact_required)}
                        >
                          <Form.Item
                            name={"ib-community-email"}
                            rules={[{
                              required: true, type: 'email'
                            }]}
                            noStyle
                          >
                          </Form.Item>
                          <span
                            className={"ib-form-label"}
                          >
                            Your Email
                          </span>
                          <br />
                          <input
                            name={"email"}
                            type={"email"}
                            className={"ib-input-new ib-form-email-new-box"}
                            placeholder={"Eg: name@gmail.com"}
                            defaultValue={iForm.getFieldValue("ib-community-email")}
                            onChange={e => iForm.setFieldsValue({"ib-community-email": e.target.value})}
                            required
                          />
                        </Form.Item>
                        <Form.Item
                          noStyle
                          hidden={!(brandDetails.both_required || brandDetails.contact_required)}
                          shouldUpdate
                        >
                          {
                            () => {
                              return(
                                <>
                                  <Form.Item
                                    name={"ib-community-contact"}
                                    rules={[{
                                      required: true
                                    }]}
                                    noStyle
                                  >
                                  </Form.Item>
                                  <label className={"ib-form-label"}>
                                    Your Phone Here
                                  </label>
                                  <div
                                    //className={"ib-form-email-comp"}
                                    className={"mb-2"}
                                  >
                                    <i
                                      className="fa fa-ig-ib fa-2x fa-phone"
                                      aria-hidden="true"
                                      hidden
                                    />
                                    <ConfigProvider locale={en}>
                                      <CountryPhoneInput
                                        placeholder={"Your Phone Here"}
                                        className={"ib-input-new"}
                                        value={contactDetails}
                                        name={"phone"}
                                        //className={"ib-input ib-instagram-email-input"}
                                        onChange={e => {
                                          setContactDetails(e)
                                          iForm.setFieldsValue({"ib-community-contact": (e?.code || "") + (e?.phone || "")})
                                        }}
                                        required
                                      />
                                    </ConfigProvider>
                                    <input
                                      name={"phone"}
                                      hidden
                                      type={"text"}
                                      className={"ib-input-new ib-form-contact-new-box"}
                                      placeholder={"Eg. 9896XXXXXX"}
                                      value={iForm.getFieldValue("ib-community-contact") || "123"}
                                      onChange={e => iForm.setFieldsValue({"ib-community-contact": e.target.value})}
                                    />
                                  </div>
                                </>
                              )
                            }
                          }
                        </Form.Item>
                        <Form.Item
                          noStyle
                        >
                          <label className={"ib-form-label"}>
                            Your Instagram Username
                          </label>
                          <Form.Item
                            name={"ib-community-instagram"}
                            noStyle
                          >
                          </Form.Item>
                          <input
                            type="text"
                            id="in-input-new ib-instagram-username-input"
                            className="ib-input-new ib-form-username-new-box"
                            placeholder={"Eg: @your_instagram_username"}
                            onChange={e => iForm.setFieldsValue({"ib-community-instagram": e.target.value})}
                            defaultValue={iForm.getFieldValue("ib-community-instagram")}
                            required
                          />
                        </Form.Item>
                        <Form.Item
                          className={`has-text-centered`}
                          shouldUpdate
                        >
                          {() => {
                            return(
                              <button
                                style={{
                                  backgroundColor: brandDetails.button_color,
                                  color: getTextColor(brandDetails.button_color)
                                }}
                                disabled={load}
                                className={`button is-dark is-rounded ${isMobile()?"is-normal my-4":"is-medium my-6"} is-uppercase px-6 has-text-weight-bold`}
                                onClick={() => formSubmit()}
                              >
                                {brandDetails.button_text}
                              </button>
                            )
                          }}
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"column is-6-mobile-only has-text-centered"}>
              <img
                src={something}
                style={{maxWidth: "80%"}}
                className={"mb-3 is-pulled-left"}
              />
              <img
                className={"ib-community-image"}
                src={brandDetails.images.length>0 ? brandDetails.images[0] : "https://uploads.dovetale.net/processed-landing-page-images/ac8da5449ec2d7541d3925da3054749d.png"}
              />
            </div>
            <div className={"column is-6-mobile-only has-text-centered"}>
              <img
                className={"ib-community-image"}
                src={brandDetails.images.length>1 ? brandDetails.images[1] : "https://uploads.dovetale.net/processed-landing-page-images/ac8da5449ec2d7541d3925da3054749d.png"}
              />
            </div>
          </div>
        </div>
        {
          brandDetails.faqs.show && (
            <FAQs
              campaign_id={brandDetails.id}
              faqItems={brandDetails.faqs.items}
            />
          )
        }
      </div>
    </div>
  )
}

export default BrandApplication