import {GetToken, SetToken} from "../../Common/ApiCall/CookieMethods";

const COOKIE_NAMES = {
  VISIT_NAME: "AmbassadorVisit",
  PLAYED: "AmbassadorSubmitted",
  ZERO: 0
}

const visitPage = () => {
  try {
    let v = getPageVisits()
    if(!isNaN(Number(v))) {
      v += 1
    } else {
      v = 1
    }
    let tokenVal = String(v)
    SetToken(tokenVal, COOKIE_NAMES.VISIT_NAME)
  } catch (e) {
    console.log(e)
  }
}

const playedGame = () => {
  let tokenVal = "yes"
  SetToken(tokenVal, COOKIE_NAMES.PLAYED)
}

const getPageVisits = () => {
  let t = GetToken(COOKIE_NAMES.VISIT_NAME)
  if(t) t = Number(t)
  return t
}

export {
  visitPage,
  COOKIE_NAMES,
  getPageVisits,
  playedGame
}